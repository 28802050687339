import TenantSelect from '@components/tenant-select'
// import {useAddUserMutation, useUpdateUserMutation} from '@services/users'
import {Drawer, Alert, Button, Form, Input, Space, Select, message} from 'antd'
import {useCRUD} from 'helpers/api'
import {useState} from 'react'
import {useSelector} from 'react-redux'

const {Option} = Select

export const formFields = {name: 'Name', email: 'Email', role: 'Role'}

const Editor = ({user, onClose, onSuccess}) => {
  const currentUser = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.settings.tenant)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const {create, update} = useCRUD('users')

  const isNew = !user?.id || !user.tid
  // const isLoading = isNew ? addStatus.isLoading : updateStatus.isLoading
  // const error = isNew ? addStatus.error : updateStatus.error
  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const onFinish = values => {
    if (isNew && !user.id) {
      setIsLoading(true)
      create(values, response => {
        if (typeof response !== 'undefined') {
          setIsLoading(false)
          if (response) {
            onSuccess()
            onClose()
            handleClose()
            message.success('Successfully created user!')
          } else {
            setError({status: 400})
          }
        }
      })
    } else {
      setIsLoading(true)
      update(user.id, values, response => {
        if (typeof response !== 'undefined') {
          setIsLoading(false)
          if (response) {
            onSuccess()
            handleClose()
            message.success('Successfully updated user!')
          } else {
            setError({status: 400})
          }
        }
      })
    }
  }

  return (
    <Drawer title={isNew ? 'Add User' : 'Edit User'} width={500} onClose={handleClose} open={!!user} closable={false}>
      <Form
        layout="vertical"
        initialValues={user}
        onFinish={onFinish}
        autoComplete="off"
        key={user ? user.id || -1 : 0}
      >
        {error && (
          <Alert
            message={error?.status === 400 ? 'User with this email already exists!' : 'Something went wrong!'}
            type="error"
            showIcon
            style={{marginBottom: 24}}
          />
        )}

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required!'
            },
            {
              min: 2,
              message: 'Must be at least 2 characters!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!'
            },
            {
              type: 'email',
              message: 'Email is not a valid email!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Company"
          name="tid"
          rules={[
            {
              required: true,
              message: 'Please select a company!'
            }
          ]}
        >
          <TenantSelect disabled={tenant?.id || currentUser?.role === 'admin' || !isNew} />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: 'Please select a role!'
            }
          ]}
        >
          <Select placeholder="Select a role" disabled={user?.id === currentUser.id}>
            <Option value="user">User</Option>
            <Option value="admin">Admin</Option>
            {!tenant && currentUser.role === 'sysadmin' && <Option value="sysadmin">Sysadmin</Option>}
            {((!tenant && currentUser.role === 'sysadmin') || currentUser.role === 'sysadminRO') && (
              <Option value="sysadminRO">Sysadmin (Read Only)</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isNew ? 'Add' : 'Save'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default Editor
