import {Col, Row} from 'antd'
import {TargetCountsCard} from './target-counts-card'
import {AlertCountsCard} from './alert-counts-card'
import {TemperatureHistogramCard} from './temperature-histogram-card'
import {widthSmall} from 'helpers/style'

const CARDS_GUTTER = 16
const DAYS_TO_SHOW = 7

export const MetricsCards = ({query}) =>
  query?.t_id || query?.s_id || query?.g_id ? (
    <Row gutter={CARDS_GUTTER} style={{margin: '1em'}}>
      <Col xs={24} md={8}>
        <TargetCountsCard query={query} width={widthSmall} />
      </Col>
      <Col xs={24} md={8}>
        <AlertCountsCard query={query} days={DAYS_TO_SHOW} width={widthSmall} />
      </Col>
      <Col xs={24} md={8}>
        <TemperatureHistogramCard query={query} width={widthSmall} />
      </Col>
    </Row>
  ) : (
    <></>
  )

export default MetricsCards
