import {Button, Table} from 'antd'
import {useEffect, useState} from 'react'
import moment from 'moment'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    white-space: pre-line;
    text-align: center;
  }
`
const getRowKey = record => {
  return `${record?.id}`
}

const getColumns = handleTargetClick => [
  {
    title: 'Target',
    dataIndex: 'tg_name',
    key: 'tg_name',
    align: 'left',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handleTargetClick({id: record.tg_id})
        }}
      >
        {record.tg_name}
      </Button>
    )
  },
  {
    title: 'Status',
    dataIndex: 'tg_status',
    key: 'tg_status',
    align: 'left'
  },
  {
    title: 'Active',
    dataIndex: 'tg_active',
    key: 'tg_active',
    align: 'center',
    render: value => (value === 1 ? 'Yes' : 'No')
  },
  {
    title: 'Last\nTemperature',
    dataIndex: 'tp_timestamp',
    key: 'tp_timestamp',
    align: 'center',
    render: value => moment(value).local().format('YYYY/MM/DD HH:mm:ss')
  },
  {
    title: 'Max\nTemp',
    dataIndex: 'tp_max',
    key: 'tp_max',
    align: 'center'
  },
  {
    title: 'Last\nImage',
    dataIndex: 'i_timestamp',
    key: 'i_timestamp',
    align: 'center',
    render: value => moment(value).local().format('YYYY/MM/DD HH:mm:ss')
  },
  {
    title: 'Last\nAlert',
    dataIndex: 'at_timestamp',
    key: 'at_timestamp',
    align: 'center',
    render: value => moment(value).local().format('YYYY/MM/DD HH:mm:ss')
  },
  {
    title: 'Alert\nType',
    dataIndex: 'at_alert_type',
    key: 'at_alert_type',
    align: 'center'
  }
]

const TargetsviewTable = ({targetsview, pagination, rowKeyFunc = getRowKey, handleTargetClick}) => {
  const [columns, setColumns] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    setColumns(getColumns(handleTargetClick))
  }, [])

  useEffect(() => {
    if (targetsview) setData(targetsview)
  }, [targetsview])

  return (
    <StyledTable
      data-testid="targetsview-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKeyFunc(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default TargetsviewTable
