import {Card} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'
import ApexChart from 'react-apexcharts'

export const TemperatureHistogramCard = ({query, width}) => {
  const [data, setData] = useState()
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState()

  const fetchTargetsview = useFetchGet('targetsview')

  const getTargetsview = () => {
    const params = Params({...query, count: 'tp_max_5x'})
    fetchTargetsview(params, response => {
      const result = response.targetsview

      // Step 1: Find the lowest and highest tp_max_5x values
      const tpMaxValues = result.map(item => item.tp_max_5x)
      const minTpMax = Math.min(...tpMaxValues)
      const maxTpMax = Math.max(...tpMaxValues)

      // Step 2: Create an array from lowest-5 to highest+5 with intervals of 5
      const numbers = []
      for (let i = minTpMax - 5; i <= maxTpMax + 5; i += 5) {
        numbers.push(i)
      }

      // Step 3: Create a counts array of the same size as numbers and initialize with 0
      const counts = Array(numbers.length).fill(0)

      // Step 4: Populate counts array with counts for each tp_max_5x in the numbers array
      result.forEach(item => {
        const index = numbers.indexOf(item.tp_max_5x)
        if (index !== -1) {
          counts[index] = item.count
        }
      })

      setData({numbers, counts})
    })
  }

  useEffect(() => {
    if (query) getTargetsview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: 'Temperature',
          data: data.counts
        }
      ])
      setOptions({
        chart: {
          height: 350,
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        title: {
          text: 'Temperatures',
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: 'black'
          }
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: false
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: data.numbers
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return `${val}-${val + 4}`
            }
          }
        },
        legend: {
          show: false
        }
      })
    } else {
      setOptions({})
    }
  }, [data])

  return (
    <Card className={`w-${width} h-52 font-bold`}>
      {data && series ? (
        <ApexChart options={options} series={series} type="bar" height={180} width={width * 0.9} />
      ) : (
        <></>
      )}
    </Card>
  )
}
