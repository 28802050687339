import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {useTenant} from '@store/settings'
import {Wrapper} from '../styles'
import Tenants from '@components/tenants'
import SitesMapRow from '@components/sites-map-row'
import MetricsCards from '../metrics-cards'

const TenantDashboard = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const navigate = useNavigate()

  const handleSiteClick = useCallback(
    site => {
      if (site?.id) {
        navigate(`/site/${site.id}`)
      }
    },
    [navigate]
  )

  if (!tenant) {
    return <Tenants handleTenantChange={setTenant} />
  }

  return (
    <Wrapper>
      <MetricsCards query={{t_id: tenant.id}} />
      <SitesMapRow tenant={tenant} handleSiteClick={handleSiteClick} />
    </Wrapper>
  )
}

export default React.memo(TenantDashboard)
