import {Card, Row, Col} from 'antd'
import LocationsMap from './locations-map'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'
import {useSitesSettings} from '@store/settings'
import {paginationDefaults, widthSmall} from 'helpers/style'
import PageHeader from './page-header'
import {DELTA_THERMAL_GLOBAL_HQ, MINZOOM, toLocations} from 'helpers/locations'
import {extractSites} from 'helpers/results'
import TargetStatusLegend from './target-status-legend'
import TargetStatusTable from './target-status-table'

const SitesMapRow = ({tenant, handleSiteClick}) => {
  const [sites, setSites] = useState()
  const [sitesMeta, setSitesMeta] = useState()
  const fetchSitesview = useFetchGet('sitesview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useSitesSettings()
  const [locations, setLocations] = useState()
  const [minZoom, setMinZoom] = useState(MINZOOM.SITES)

  const getSites = () => {
    const params = Params({tid: tenant?.id, orderBy: 's_name', order: 'ASC', filter, limit, page})
    fetchSitesview(params, response => {
      const sites = extractSites(response.sitesview)
      setSites(sites)
      setSitesMeta(response.meta)
      console.log('sites=' + JSON.stringify(sites))
      onSitesChange(sites)
    })
  }

  useEffect(() => {
    if (tenant) getSites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, filter])

  const onSitesChange = sites => {
    if (sites && sites.length > 0) {
      setLocations(toLocations(sites))
    } else {
      // Show DT Headquarters location; leave off id to make not clickable
      setLocations(toLocations([DELTA_THERMAL_GLOBAL_HQ]))
      setMinZoom(MINZOOM.SITES)
    }
  }

  return (
    <Row gutter={16} style={{margin: '15px'}}>
      {/* Sites Card - takes 1/4 width */}
      <Col xs={24} md={8}>
        <Card className={`w-${widthSmall}`}>
          <PageHeader ghost={false} onBack={() => window.history.back()} title="Sites" subTitle="" backIcon={false} />
          <TargetStatusLegend mode={'horizontal'} />
          <TargetStatusTable
            items={sites}
            handleItemClick={handleSiteClick}
            pagination={paginationDefaults(sitesMeta?.total, limit, sitesMeta?.page, setPage, setLimit)}
          />
        </Card>
      </Col>

      {/* Locations Map Card - takes 3/4 width */}
      <Col xs={24} md={16}>
        <Card className="max-w-full">
          <div style={{height: '50vh', width: '60vw'}}>
            <LocationsMap
              locations={locations}
              onLocationClick={location => handleSiteClick(location)}
              minZoom={minZoom}
            />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default SitesMapRow
