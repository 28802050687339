import ArrowLeft from '@ant-design/icons/LeftCircleTwoTone'
import ArrowRight from '@ant-design/icons/RightCircleTwoTone'
import classNames from 'classnames'
import ResizeObserver from 'rc-resize-observer'
import useState from 'rc-util/lib/hooks/useState'
import * as React from 'react'
import {Space, Breadcrumb, Avatar} from 'antd'
import {ConfigConsumer} from 'antd/es/config-provider'
import TransButton from '../trans-button'
import './styles.css'

// export interface PageHeaderProps {
//   backIcon?;
//   prefixCls?: string;
//   title?;
//   subTitle?;
//   style?: React.CSSProperties;
//   breadcrumb?: BreadcrumbProps | React.ReactElement<typeof Breadcrumb>;
//   breadcrumbRender?: (props: PageHeaderProps, defaultDom) => React.ReactNode;
//   tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
//   footer?;
//   extra?;
//   avatar?: AvatarProps;
//   onBack?: (e?: React.MouseEvent<HTMLDivElement>) => void;
//   className?: string;
//   ghost?: boolean;
//   children?;
// }

const renderBack = (prefixCls, backIcon, onBack) => {
  if (!backIcon || !onBack) {
    return null
  }
  return (
    <div className={`${prefixCls}-back`}>
      <TransButton
        onClick={e => {
          onBack?.(e)
        }}
        className={`${prefixCls}-back-button`}
        data-testid="page-header-back-button"
      >
        {backIcon}
      </TransButton>
    </div>
  )
}

const renderBreadcrumb = breadcrumb => <Breadcrumb {...breadcrumb} />

const getBackIcon = (props, direction = 'ltr', fontSize = 24) => {
  if (props.backIcon !== undefined) {
    return props.backIcon
  }

  const IconComponent = direction === 'rtl' ? ArrowRight : ArrowLeft

  return <IconComponent style={{fontSize: fontSize}} />
}

const renderTitle = (prefixCls, props, direction = 'ltr') => {
  const {title, avatar, subTitle, tags, extra, onBack} = props
  const headingPrefixCls = `${prefixCls}-heading`
  const hasHeading = title || subTitle || tags || extra
  // If there is nothing, return a null
  if (!hasHeading) {
    return null
  }
  const backIcon = getBackIcon(props, direction)
  const backIconDom = renderBack(prefixCls, backIcon, onBack)
  const hasTitle = backIconDom || avatar || hasHeading
  return (
    <div className={headingPrefixCls}>
      {hasTitle && (
        <div className={`${headingPrefixCls}-left`}>
          {backIconDom}
          {avatar && <Avatar {...avatar} />}
          {title && (
            <span className={`${headingPrefixCls}-title`} title={typeof title === 'string' ? title : undefined}>
              {title}
            </span>
          )}
          {subTitle && (
            <span
              className={`${headingPrefixCls}-sub-title`}
              title={typeof subTitle === 'string' ? subTitle : undefined}
            >
              {subTitle}
            </span>
          )}
          {tags && <span className={`${headingPrefixCls}-tags`}>{tags}</span>}
        </div>
      )}
      {extra && (
        <span className={`${headingPrefixCls}-extra`}>
          <Space>{extra}</Space>
        </span>
      )}
    </div>
  )
}

const renderFooter = (prefixCls, footer) => {
  if (footer) {
    return <div className={`${prefixCls}-footer`}>{footer}</div>
  }
  return null
}

const renderChildren = (prefixCls, children) => <div className={`${prefixCls}-content`}>{children}</div>

const PageHeader = props => {
  const [compact, updateCompact] = useState(false)
  const onResize = width => {
    updateCompact(width < 768, true)
  }
  return (
    <ConfigConsumer>
      {({getPrefixCls, pageHeader, direction}) => {
        const {
          prefixCls: customizePrefixCls,
          style,
          footer,
          children,
          breadcrumb,
          breadcrumbRender,
          className: customizeClassName
        } = props
        let ghost = true

        // Use `ghost` from `props` or from `ConfigProvider` instead.
        if ('ghost' in props) {
          ghost = props.ghost
        } else if (pageHeader && 'ghost' in pageHeader) {
          ghost = pageHeader.ghost
        }

        const prefixCls = getPrefixCls('page-header', customizePrefixCls)

        const getDefaultBreadcrumbDom = () => {
          if (breadcrumb?.routes) {
            return renderBreadcrumb(breadcrumb)
          }
          return null
        }

        const defaultBreadcrumbDom = getDefaultBreadcrumbDom()

        const isBreadcrumbComponent = breadcrumb && 'props' in breadcrumb
        // support breadcrumbRender function
        const breadcrumbRenderDomFromProps = breadcrumbRender?.(props, defaultBreadcrumbDom) ?? defaultBreadcrumbDom

        const breadcrumbDom = isBreadcrumbComponent ? breadcrumb : breadcrumbRenderDomFromProps

        const className = classNames(prefixCls, customizeClassName, {
          'has-breadcrumb': !!breadcrumbDom,
          'has-footer': !!footer,
          [`${prefixCls}-ghost`]: ghost,
          [`${prefixCls}-rtl`]: direction === 'rtl',
          [`${prefixCls}-compact`]: compact
        })

        return (
          <ResizeObserver onResize={onResize}>
            <div className={className} style={style}>
              {breadcrumbDom}
              {renderTitle(prefixCls, props, direction)}
              {children && renderChildren(prefixCls, children)}
              {renderFooter(prefixCls, footer)}
            </div>
          </ResizeObserver>
        )
      }}
    </ConfigConsumer>
  )
}

export default PageHeader
