import './gateway.css'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Card} from '@tremor/react'
import {Spin} from 'antd'

import PageHeader from '@components/page-header'
import {Wrapper} from '../styles'
import MetricsCards from '../metrics-cards'

import {Params, useFetchGet} from 'helpers/api'
import {capitalizeFirstLetter, widthLarge} from 'helpers/style'
import {TargetsviewTableCard} from '../targetsview-table-card'
import GridRow from '@components/grid-row'
import {RailGWStatusTableCard} from './railgwstatus-table-card'

const LoadingState = () => (
  <div className="flex h-screen items-center justify-center">
    <Spin size="large" />
  </div>
)

const ErrorState = ({error}) => (
  <div className="flex h-screen items-center justify-center">
    <Card className="p-6 text-center">
      <h2 className="text-xl font-semibold text-red-600">Error</h2>
      <p className="mt-2">{error}</p>
    </Card>
  </div>
)

const Gateway = () => {
  const {gid} = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [gatewayView, setGatewayView] = useState(null)
  const fetchGatewaysView = useFetchGet('gatewaysview')

  const handleTargetClick = target => {
    if (target?.id) {
      navigate(`/target/${target.id}`)
    }
  }
  const getTitle = () => {
    if (!gatewayView) return ''
    return `Gateway: ${gatewayView.s_name} > ${gatewayView.g_name} - Type: ${capitalizeFirstLetter(gatewayView.g_type)}`
  }

  const fetchGatewayData = async () => {
    try {
      setIsLoading(true)
      setError(null)

      const params = Params({g_id: gid, limit: 1})
      await fetchGatewaysView(params, response => {
        if (!response?.gatewaysview?.length) {
          throw new Error('Gateway not found')
        }
        setGatewayView(response.gatewaysview[0])
      })
    } catch (err) {
      setError(err.message || 'Failed to fetch gateway data')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (gid) {
      fetchGatewayData()
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [gid])

  if (isLoading) {
    return <LoadingState />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!gatewayView) {
    return null
  }

  return (
    <main className="min-h-screen bg-gray-50">
      <PageHeader ghost={false} onBack={() => window.history.back()} title={getTitle()} subTitle="" />

      <Wrapper>
        <MetricsCards query={{g_id: gatewayView.g_id}} />
        <GridRow layout={[24]}>
          <TargetsviewTableCard
            query={{g_id: gatewayView.g_id}}
            width={widthLarge}
            handleTargetClick={handleTargetClick}
          />
        </GridRow>
      </Wrapper>

      {gatewayView.g_type === 'rail' && (
        <GridRow layout={[24]}>
          <RailGWStatusTableCard query={{gid: gatewayView.g_id}} />
        </GridRow>
      )}
    </main>
  )
}

export default Gateway
