import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Form, Input, Select, Space, message, Typography} from 'antd'
import {useCRUD} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography
const {Option} = Select

export const formFields = {name: 'Name', type: 'Type'}

export const buildGateway = (isNew, action, item) => {
  if (isNew || action === 'Add') {
    return {
      tid: item?.tenant?.id,
      sid: isNew ? '' : item?.site?.id,
      name: '',
      type: ''
    }
  } else {
    return {
      ...item?.gateway,
      sid: item?.gateway?.id ? item?.gateway?.sid : item?.site?.id,
      tid: item?.tenant?.id
    }
  }
}

const GatewayForm = ({gateway, selectedAction, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(gateway)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const {create, read, update} = useCRUD('gateways')

  const handleClose = () => {
    setError()
    setIsLoading(false)
    onClose()
  }

  const fetchItem = () => {
    read(gateway.id, response => {
      setFormData({...response})
    })
  }

  useEffect(() => {
    if (gateway?.id) {
      fetchItem()
    } else {
      setFormData(gateway)
    }
  }, [gateway, selectedAction])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, selectedAction])

  const onFinish = values => {
    const body = {...values, stid: ''}

    if (!gateway?.id) {
      setIsLoading(true)
      create(body, response => {
        console.log('response', JSON.stringify(response))
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully created gateway!')
        } else {
          setError({status: 400})
        }
      })
    } else {
      setIsLoading(true)
      update(gateway.id, body, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully updated gateway!')
        } else {
          setError({status: 400})
        }
      })
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 10, offset: 0}}
      initialValues={gateway}
      onFinish={onFinish}
      autoComplete="off"
      key={gateway ? gateway.id || -1 : 0}
      onValuesChange={changedValues => {
        const data = {...formData, ...changedValues}
        if (changedValues.tid) data.sid = null
        setFormData(data)
      }}
      form={form}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Gateway with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 6, offset: 10}}>
        <Title level={4}>Gateway</Title>
      </Form.Item>

      <Form.Item
        label="Company"
        name="tid"
        rules={[
          {
            required: !gateway.tid,
            message: 'Please select a company!'
          }
        ]}
      >
        <TenantSelect disabled={!!gateway.tid} />
      </Form.Item>

      <Form.Item
        label="Site"
        name="sid"
        rules={[
          {
            required: !gateway.sid,
            message: 'Please select a site!'
          }
        ]}
      >
        <SiteSelect disabled={!!gateway.sid} tid={formData.tid} />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required!'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: 'Please select a gateway type!'
          }
        ]}
      >
        <Select placeholder="Select a type">
          <Option value="mosaic">Mosaic</Option>
          <Option value="rail">Rail</Option>
        </Select>
      </Form.Item>

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            {!gateway?.id ? 'Add' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default GatewayForm
