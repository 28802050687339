import {Card} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'
import RailGWStatusTable from './railgwstatus-table'
import PageHeader from '@components/page-header'

export const RailGWStatusTableCard = ({query, width}) => {
  const [data, setData] = useState()

  const fetchRailgwstatusview = useFetchGet('railgwstatus')

  const getRailGWStatus = () => {
    const params = Params(query)
    fetchRailgwstatusview(params, response => {
      const result = response.railgwstatus
      setData(result)
    })
  }

  useEffect(() => {
    if (query?.gid) getRailGWStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (!data) return <></>

  return (
    <Card className={`w-${width}`}>
      <PageHeader ghost={false} title="Rail Gateway Status Events" subTitle="" backIcon={false} />
      <RailGWStatusTable railgwstatus={data} pagination={{}} />
    </Card>
  )
}
