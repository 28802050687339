import {Table} from 'antd'
import {useEffect, useState} from 'react'
import moment from 'moment'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    white-space: pre-line;
    text-align: center;
  }
`
const getRowKey = record => {
  return `${record?.gid}-${record?.timestamp}`
}

const getColumns = () => [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    align: 'center',
    render: (text, row) => {
      return moment(row.timestamp).local().format('yyyy/MM/DD h:mm:ss A')
    }
  },
  {
    title: 'Latitude',
    dataIndex: 'latitude',
    key: 'latitude',
    align: 'center'
  },
  {
    title: 'Longitude',
    dataIndex: 'longitude',
    key: 'longitude',
    align: 'center'
  },
  {
    title: 'Position\nAccuracy',
    dataIndex: 'position_accuracy',
    key: 'position_accuracy',
    align: 'center'
  },
  {
    title: 'Speed',
    dataIndex: 'speed',
    key: 'speed',
    align: 'center'
  },
  {
    title: 'Speed\nAccuracy',
    dataIndex: 'speed_accuracy',
    key: 'speed_accuracy',
    align: 'center'
  },
  {
    title: 'Heading',
    dataIndex: 'heading',
    key: 'heading',
    align: 'center'
  },
  {
    title: 'Heading\nAccuracy',
    dataIndex: 'heading_accuracy',
    key: 'heading_accuracy',
    align: 'center'
  },
  {
    title: 'Battery',
    dataIndex: 'battery_percent',
    key: 'battery_percent',
    align: 'center'
  },
  {
    title: 'Voltage',
    dataIndex: 'voltage_input',
    key: 'voltage_input',
    align: 'center'
  },
  {
    title: 'Current',
    dataIndex: 'current_output',
    key: 'current_output',
    align: 'center'
  },
  {
    title: 'Charge State',
    dataIndex: 'charge_state',
    key: 'charge_state'
  }
]

const RailGWStatusTable = ({railgwstatus, pagination, rowKeyFunc = getRowKey}) => {
  const [columns, setColumns] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    setColumns(getColumns())
  }, [])

  useEffect(() => {
    if (railgwstatus) setData(railgwstatus)
  }, [railgwstatus])

  return (
    <StyledTable
      data-testid="railgwstatus-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKeyFunc(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default RailGWStatusTable
