import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import PageHeader from '@components/page-header'
import {useFetchGetId} from 'helpers/api'
import GatewaysMapRow from '@components/gateways-map-row'
import {Wrapper} from '../styles'
import MetricsCards from '../metrics-cards'

const SiteDashboard = () => {
  const {sid} = useParams()
  const navigate = useNavigate()
  const [site, setSite] = useState()
  const fetchSite = useFetchGetId('sites')
  const [title, setTitle] = useState()

  const handleGatewayClick = gateway => {
    if (gateway?.id) {
      navigate(`/gateway/${gateway?.id}`)
    }
  }

  const getSite = async () => {
    await fetchSite(sid, response => {
      setSite(response)
    })
  }

  useEffect(() => {
    if (sid) getSite()
  }, [sid])

  useEffect(() => {
    site ? setTitle(`Site: ${site.name}`) : setTitle('')
  }, [site])

  if (!site) return null

  return (
    <>
      <PageHeader ghost={false} onBack={() => window.history.back()} title={title} subTitle="" extra={[]} />
      <Wrapper>
        <MetricsCards query={{s_id: site?.id}} />
        <GatewaysMapRow site={site} handleGatewayClick={handleGatewayClick} />
      </Wrapper>
    </>
  )
}

export default SiteDashboard
