import React from 'react'
import {Row, Col} from 'antd'

const GridRow = ({gutter = 16, style = {margin: '15px'}, layout = [], children}) => {
  // Ensure children match the layout length
  const contentArray = React.Children.toArray(children)

  return (
    <Row gutter={gutter} style={style}>
      {layout.map((colSpan, index) => (
        <Col key={index} xs={24} md={colSpan}>
          {contentArray[index]}
        </Col>
      ))}
    </Row>
  )
}

export default GridRow
