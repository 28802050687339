/* eslint-disable react-hooks/exhaustive-deps */
import {getFeatureMenuItems, getUserMenuItems} from './menus'
import {Header, UserMenu} from './styles'
import LogoUrl from '@assets/images/logo-white.png'
import {TenantTitle} from '@components/tenant-title'
import {logout} from '@store/auth'
import {useTenant} from '@store/settings'
import {Layout, Menu} from 'antd'
import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useFetchPatch} from 'helpers/api'

const {Content, Footer} = Layout

const MainLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {user} = useSelector(state => state.auth)
  const [tenant, setTenant] = useTenant()
  const patchUser = useFetchPatch('users')

  const signout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const handleClearTenant = useCallback(() => {
    setTenant(null)
  }, [setTenant])

  const handleSelectTenant = useCallback(
    (tenant, user) => {
      console.log('user:', user)
      patchUser('me', user, () => {
        window.location.href = '/'
      })
    },
    [patchUser]
  )

  if (!user?.email) {
    console.log('TRACE', 'MainLayout !user?.email', 'navigate to /auth')
    return <Navigate to="/auth" />
  }

  return (
    <Layout>
      <Header style={{position: 'sticky', top: 0, zIndex: 100, height: '65px', width: '100%', margin: 0, padding: 0}}>
        <a className="logo" href="https://deltathermalinc.com/" target="_blank" rel="noreferrer">
          <img src={LogoUrl} alt="logo" />
        </a>
        <TenantTitle
          user={user}
          tenant={tenant}
          onClearTenant={handleClearTenant}
          onSelectTenant={handleSelectTenant}
        />
        <UserMenu
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 101,
            width: '40%',
            justifyContent: 'end',
            backgroundColor: 'black',
            color: 'white'
          }}
          theme="dark"
          mode="horizontal"
          items={getUserMenuItems(user, signout)}
        />
        <Menu
          style={{
            position: 'absolute',
            top: 65,
            margin: 0,
            padding: 0,
            height: '2rem',
            width: '100%',
            lineHeight: '2rem'
          }}
          theme="light"
          mode="horizontal"
          selectedKeys={[location.pathname.slice(1)]}
          items={getFeatureMenuItems(user)}
        />
      </Header>
      <Content className="site-layout">
        <div>
          <Outlet />
        </div>
      </Content>
      <Footer style={{textAlign: 'center'}}>Delta Thermal ©2023</Footer>
    </Layout>
  )
}

export default React.memo(MainLayout)
