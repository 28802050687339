import {Button} from 'antd'
import ItemsTable from './items-table'
import {StackedBars} from './stacked-bars'

const getRowKey = record => {
  return `${record?.id}`
}

const getColumns = handleItemClick => [
  {
    title: 'Name',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handleItemClick(record)
        }}
      >
        {record.name}
      </Button>
    )
  },
  {
    title: 'Targets Status',
    key: 'tg_status',
    render: (text, row) => <StackedBars data={getSeries(row)} />,
    align: 'center'
  }
]

const getSeries = item => {
  return [
    item.tg_status_none_count,
    item.tg_status_offline_count,
    item.tg_status_online_count,
    item.tg_status_normal_count,
    item.tg_status_warning_count,
    item.tg_status_critical_count,
    item.tg_status_error_count
  ]
}

const TargetStatusTable = ({items, pagination, handleItemClick, rowKeyFunc = getRowKey, columnsFunc = getColumns}) => {
  return items ? (
    <ItemsTable
      items={items}
      handleItemClick={handleItemClick}
      pagination={pagination}
      rowKeyFunc={rowKeyFunc}
      columnsFunc={columnsFunc}
    />
  ) : (
    <></>
  )
}

export default TargetStatusTable
