import {Card} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'
import ApexChart from 'react-apexcharts'

const colors = ['#44E493', '#FFA500', '#A0C4FF']
const lighterColors = ['#72EAAE', '#FFBB3F', '#B7D2FF']
const evenLighterColors = ['#A1F1C9', '#FFD27F', '#CFE1FF']
const evenmoreLighterColors = ['#D0F8E4', '#FFE8BF', '#E7F0FF']

export const TargetCountsCard = ({query, width}) => {
  const [data, setData] = useState()
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState()

  const fetchTargetsview = useFetchGet('targetsview')

  const getTargetsview = () => {
    const params = Params({...query, count: 'tg_status,tg_active'})
    fetchTargetsview(params, response => {
      const result = response.targetsview
      const totalCount = result.reduce((acc, item) => acc + item.count, 0)
      const countMonitored = result.filter(item => item.tg_status !== 'none').reduce((acc, item) => acc + item.count, 0)
      const countActive = result.filter(item => item.tg_active === 1).reduce((acc, item) => acc + item.count, 0)
      setData({total: totalCount, monitored: countMonitored, active: countActive})
    })
  }

  useEffect(() => {
    if (query) getTargetsview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: 'Targets',
          data: [data.active, data.monitored, data.total]
        }
      ])
      setOptions({
        chart: {
          height: 350,
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        title: {
          text: 'Targets',
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: 'black'
          }
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
            colors: {
              backgroundBarColors: evenmoreLighterColors
            }
          }
        },
        colors: colors,
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: ['Active', 'Monitored', 'Total'],
          colors: colors
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      })
    } else {
      setOptions({})
    }
  }, [data])

  return (
    <Card className={`w-${width} h-52 font-bold`}>
      {data && series ? (
        <ApexChart options={options} series={series} type="bar" height={180} width={width * 0.9} />
      ) : (
        <></>
      )}
    </Card>
  )
}
