import {Table} from 'antd'
import {useEffect, useState} from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {Tooltip} from 'antd'

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    white-space: pre-line;
    text-align: center;
  }
`
const getRowKey = record => {
  return `${record?.gid}-${record?.timestamp}`
}

//       {key: 'timestamp', type: 'timestamp'},
//       {key: 'latitude', type: 'string'},
//       {key: 'longitude', type: 'string'},
//       {key: 'position_accuracy', type: 'number'},
//       {key: 'speed', type: 'number'},
//       {key: 'speed_accuracy', type: 'number'},
//       {key: 'heading', type: 'number'},
//       {key: 'heading_accuracy', type: 'number'},
//       {key: 'voltage', type: 'number'},
//       {key: 'current', type: 'number'},
//       {key: 'charge_status', type: 'string'},
//       {key: 'vbus_status', type: 'string'},
//       {key: 'error_reason', type: 'string'},
// { key: 'error_sensor', type: 'string' },

const getColumns = () => [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    align: 'center',
    render: (text, row) => moment(row.timestamp).local().format('yyyy/MM/DD h:mm:ss A')
  },
  {
    title: 'Location (Accuracy)',
    dataIndex: 'location',
    key: 'location',
    align: 'center',
    render: (text, row) => {
      const lat = row.latitude ? parseFloat(row.latitude).toFixed(3) : 'N/A'
      const lon = row.longitude ? parseFloat(row.longitude).toFixed(3) : 'N/A'
      const accuracy = row.position_accuracy || 'N/A'
      return `${lat}, ${lon} (${accuracy})`
    }
  },
  {
    title: 'Speed (Accuracy)',
    dataIndex: 'speed',
    key: 'speed',
    align: 'center',
    render: (text, row) => {
      const speed = row.speed
      const accuracy = row.speed_accuracy || 'N/A'
      return `${speed} (${accuracy})`
    }
  },
  {
    title: 'Heading (Accuracy)',
    dataIndex: 'heading',
    key: 'heading',
    align: 'center',
    render: (text, row) => {
      const heading = row.heading
      const accuracy = row.heading_accuracy || 'N/A'
      return `${heading} (${accuracy})`
    }
  },
  {
    title: 'Voltage',
    dataIndex: 'voltage',
    key: 'voltage',
    align: 'center'
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    align: 'center'
  },
  {
    title: 'Charge Status',
    dataIndex: 'charge_status',
    key: 'charge_status',
    align: 'center',
    render: text => (
      <Tooltip title={text}>
        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>
          {text}
        </div>
      </Tooltip>
    )
  },
  {
    title: 'VBus Status',
    dataIndex: 'vbus_status',
    key: 'vbus_status',
    align: 'center',
    render: text => (
      <Tooltip title={text}>
        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>
          {text}
        </div>
      </Tooltip>
    )
  },
  {
    title: 'Error Reason',
    dataIndex: 'error_reason',
    key: 'error_reason',
    align: 'center',
    render: text => (
      <Tooltip title={text}>
        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>
          {text}
        </div>
      </Tooltip>
    )
  },
  {
    title: 'Error Sensor',
    dataIndex: 'error_sensor',
    key: 'error_sensor',
    align: 'center',
    render: text => (
      <Tooltip title={text}>
        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>
          {text}
        </div>
      </Tooltip>
    )
  }
]

const RailGWStatusTable = ({railgwstatus, pagination, rowKeyFunc = getRowKey}) => {
  const [columns, setColumns] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    setColumns(getColumns())
  }, [])

  useEffect(() => {
    if (railgwstatus) setData(railgwstatus)
  }, [railgwstatus])

  return (
    <StyledTable
      data-testid="railgwstatus-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKeyFunc(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default RailGWStatusTable
